import React from 'react';
import PropTypes from 'prop-types';
import Bot from '../../templates/BotPlatform/pages/Bot';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
const host = process.env.GATSBY_HOST;

const facebook = {
  header: {
    subTitle: 'botPlatformFacebookTitle',
    text: 'botPlatformFacebookSubTitle',
    logo: 'bot_facebook@2x.png',
    button: {
      text: 'botPlatformFacebookButtonText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`,
    }
  },
  list: [
    {
      key: 'keyboard',
      title: 'botPlatformFacebookKeyboardTitle',
      subTitle: 'botPlatformFacebookKeyboardSubTitle',
      image: 'facebook_keyboards.png',
    },
    {
      key: 'templates',
      title: 'botPlatformFacebookTemplatesTitle',
      subTitle: 'botPlatformFacebookTemplatesSubTitle',
      image: 'facebook_templates.png',
    },
    {
      key: 'contactCenter',
      title: 'botPlatformFacebookContactCenterTitle',
      subTitle: 'botPlatformFacebookContactCenterSubTitle',
      image: 'facebook_contact-center.png',
    },
    {
      key: 'profile',
      title: 'botPlatformFacebookProfileTitle',
      subTitle: 'botPlatformFacebookProfileSubTitle',
      image: 'facebook_profile.png',
    },
    {
      key: 'deepLink',
      title: 'botPlatformFacebookDeepLinkTitle',
      subTitle: 'botPlatformFacebookDeepLinkSubTitle',
      linkTitle: 'botPlatformFacebookDeepLinkLink',
      image: 'facebook_deep-link.png',
    },
    {
      key: 'richMedia',
      title: 'botPlatformFacebookRichMediaTitle',
      subTitle: 'botPlatformFacebookRichMediaSubTitle',
      image: 'facebook_rich-media.png',
    },
  ],
  enterprise: {
    title: 'botPlatformEnterpriseTitle',
    list: [
      {
        key: 'security',
        title: 'botPlatformEnterpriseSecurityTitle',
        subTitle: 'botPlatformEnterpriseSecuritySubTitle',
        image: 'security.png',
      },
      {
        key: 'performance',
        title: 'botPlatformEnterprisePerformanceTitle',
        subTitle: 'botPlatformEnterprisePerformanceSubTitle',
        image: 'performance.png',
      },
      {
        key: 'scale',
        title: 'botPlatformEnterpriseScaleTitle',
        subTitle: 'botPlatformEnterpriseScaleSubTitle',
        image: 'scale.png',
      }
    ]
  },
  clients: {
    title: 'botPlatformFacebookClientsTitle',
    list: [
      {key: 'portmone', image: 'portmone.png'},
      {key: 'iqos', image: 'iqos.png',},
      {key: 'varus', image: 'varus.png'},
      {key: 'metro', image: 'metro.png'},
      {key: 'novaPoshta', image: 'nova-poshta.png'},
      {key: 'comfy', image: 'comfy.png'},
      {key: 'pumb', image: 'pumb.png'},
      {key: 'jooble', image: 'jooble.png'},
    ]
  },
  form: {
    type: 'Facebook',
    text: 'botPlatformFacebookFormTitle',
    phoneLeft: 'facebook_phone-left.png',
    phoneRight: 'facebook_phone-right.png',
  }
};

const TelegramPage = (props) => (
  <Layout page={'bot-platform'} {...props}>
    <Bot
      content={facebook}
      type={'facebook'}
      data={props.data}
      {...props}
    />
  </Layout>
);

TelegramPage.propTypes = {
  data: PropTypes.object
};

export default withIntl(TelegramPage);
